<template>
    <v-menu
        v-model="selector"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
        :disabled="!editing"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                :value="formatValue"
                :label="label"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
                :clearable="editing"
                @click:clear="date = ''"
            ></v-text-field>
        </template>
        <v-date-picker v-model="date" @change="selector = false" first-day-of-week="1"></v-date-picker>
    </v-menu>
</template>
<script>
import { format, parse } from 'date-fns';
export default {
    name: 'selector-date',
    data: () => ({
        date: '',
        selector: false,
        displayFormat: 'dd/MM/yyyy',
        selectorFormat: 'yyyy-MM-dd',
    }),
    mounted() {
        this.date = this.value ? format(this.value, this.selectorFormat, new Date()) : '';
    },
    props: {
        value: {},
        label: {
            type: String,
            default: 'date',
        },
        editing: {
            default: true,
        },
    },
    watch: {
        date: function (value) {
            this.$emit('input', parse(value, this.selectorFormat, new Date()));
        },
        value: function (value) {
            this.date = format(value, this.selectorFormat);
        },
    },
    computed: {
        formatValue() {
            return this.value ? format(this.value, this.displayFormat) : '';
        },
    },
    methods: {},
};
</script>
